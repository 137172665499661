import React, { createContext, useCallback, useState, useContext } from 'react';
import Carregando from '../components/Carregando';
import apiDoczen from '../services/APIDoczen';

interface IContextoCarregando {
    estaCarregando: boolean;
}

const ContextoCarregando = createContext<IContextoCarregando>({} as IContextoCarregando);

export const CarregandoProvider: React.FC = ({ children }) => {

    const [estaCarregando, setEstaCarregando] = useState(false);

    const pegarRequisicoesApi = useCallback(() => {
        apiDoczen.addRequestTransform((request) => {
            setEstaCarregando(true);
        });
        apiDoczen.addResponseTransform((response) => {
            setTimeout(() => {
                setEstaCarregando(false);
            }, 400);
        });
    }, []);

    pegarRequisicoesApi();

    return (
        <ContextoCarregando.Provider value={{ estaCarregando }}>
            {children}
            <Carregando />
        </ContextoCarregando.Provider>
    );
}

export const useCarregando = (): IContextoCarregando => {
    const contexto = useContext(ContextoCarregando);
    return contexto;
}