import React from 'react';

import { Container } from './styles';

interface IconeProjetoProps {
    nomeImagem: string;
}

const IconeProjeto: React.FC<IconeProjetoProps> = ({ nomeImagem }) => {
    const imagemValida = (nomeImagem?.length > 0);
    const sourceImagem = `${process.env.REACT_APP_NOWIMG_URL_API}/imagens/${nomeImagem}`;

    if(imagemValida){
        return (
            <Container src={sourceImagem}/>
        );
    }

    return <></>
}

export default IconeProjeto;