import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from './styles/global';
import LayoutBase from './components/LayoutBase';
import { CarregandoProvider } from './contexts/HCarregando';

const App = () => {
  return (
    <CarregandoProvider>
        <BrowserRouter>
          <LayoutBase />
          <GlobalStyle />
        </BrowserRouter>
    </CarregandoProvider>
  );
}

export default App;
