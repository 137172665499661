import IAPI from "../models/IAPI";
import IResposta from "../models/IResposta";
import apiDoczen from "./APIDoczen";

interface RespostaAutenticacao {
    token: string;
    resultado: {
        notificacoes: string[];
        sucesso: boolean;
    };
}
export default class ServiceAPIs{

    public async ObterAPIs():Promise<IResposta<IAPI[]>>{
        await this.Autenticar();
        const {data} = await apiDoczen.get<IResposta<IAPI[]>>('api/v1/projeto');
        return data || {sucesso:false} as IResposta<IAPI[]>;
    }

    public async PesquisarAPIs(pesquisa:string):Promise<IResposta<IAPI[]>>{
        this.Autenticar();
        const {data} = await apiDoczen.get<IResposta<IAPI[]>>(`api/v1/projeto/pesquisa/${pesquisa}`);
        return data || {sucesso:false} as IResposta<IAPI[]>;
    }

    private async Autenticar(){
        const email = process.env.REACT_APP_LOGIN_EMAIL_DOCZEN || '';
        const senha = process.env.REACT_APP_LOGIN_SENHA_DOCZEN || '';
        const { data } = await apiDoczen.post<RespostaAutenticacao>('api/v1/autenticacao', { email, senha });
        if (data) {
            if (data.resultado?.sucesso) {
                apiDoczen.setHeader('Authorization', `Bearer ${data.token}`);
            }
        }
    }
}