import styled, { css } from 'styled-components';

interface ContainerProps{
    focado:boolean;
}

interface InputProps{
    focado:boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #F0F0F0;
  border: 2px solid #F0F0F0;
  border-radius: 10px;
  max-width: 300px;
  padding: 5px;

  display: flex;
  align-items: center;
  gap: 5px;

  ${({focado}) => focado && css`
  background: #ffffff;
  border: 2px solid #199dc4;
  `};
`;

export const Input = styled.input<InputProps>`
background: #F0F0F0;
border: 0;
width: 100%;
color: #888888;

${({focado}) => focado && css`
background: #ffffff;
`};
`;
