import React from 'react';
import { Container } from './styles';
import logo from '../../assets/images/LogoRGBsys.png';

const LogoRGBsys: React.FC = () => {
  return (
      <Container src={logo}/>
  );
}

export default LogoRGBsys;