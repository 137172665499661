import React, { InputHTMLAttributes, useState } from 'react';
import {BiSearch} from 'react-icons/bi';
import { Container, Input } from './styles';

const InputPesquisa: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({ ...rest }) => {

    const [focado, setFocado] = useState(false);

    return (
        <Container focado={focado}>
            <BiSearch color={focado?'#199dc4':'#4c555a'}/>
            <Input focado={focado} {...rest}
                onFocus={() => setFocado(true)}
                onBlur={() => setFocado(false)} 
                placeholder="Pesquise"/>
        </Container>
    );
}

export default InputPesquisa;